import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Card, CardContent, CircularProgress, Container, Fade, Stack, Typography} from '@mui/material';
import {keyframes, styled} from '@mui/system';
import {CheckCircleOutline, Favorite} from '@mui/icons-material';
import axios from "axios";

// Timings for each step of the animation chain
const ICON_ANIMATION_DURATION = 1000; // 1s scale-up/down
const TEXT_DELAY = 300;               // 0.3s after icon transforms
const BUTTON_DELAY = 500;             // 0.5s after text changes

// Keyframes for gentle pulsation when idle
const pulse = keyframes`
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
`;

// Keyframes for the single scale-up/down animation on click
const scaleUpDown = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(2);
    }
    100% {
        transform: scale(1);
    }
`;

// A styled <Favorite> icon that gently pulsates by default
const PulsatingHeart = styled(Favorite)(({theme}) => ({
  fontSize: '5rem',
  color: 'red',
  animation: `${pulse} 1.5s infinite ease-in-out`,
}));

// This container conditionally plays the scale-up/down animation
const HeartContainer = styled('div')(({playAnimation}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  animation: playAnimation ? `${scaleUpDown} ${ICON_ANIMATION_DURATION}ms ease-in-out` : 'none',
}));

function OnboardingCheckElderly() {
  const navigate = useNavigate();

  // 1) "LOADING" -> spinner
  // 2) "READY"   -> heart UI + Send your love
  const [phase, setPhase] = useState('LOADING');

  // If user has clicked "Send your love" & the scaleUpDown is playing
  const [loveAnimationPlaying, setLoveAnimationPlaying] = useState(false);
  // Once the heart changes to a check
  const [loveSent, setLoveSent] = useState(false);
  // After the text changes, we finally show the dashboard button
  const [showDashboardButton, setShowDashboardButton] = useState(false);

  useEffect(() => {
    // Simulate a 3-second waiting period
    const timer = setTimeout(() => {
      setPhase('READY');
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleSendLove = async () => {
    setLoveAnimationPlaying(true);
    const token = localStorage.getItem('accessToken');
    const elderlyId = localStorage.getItem('elderlyId');


    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/send-emoji`,
      {elderlyId},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // Handle success animation
    setTimeout(() => {
      setLoveAnimationPlaying(false);
      // 2) Wait another 300ms before showing the check & text change
      setTimeout(() => {
        setLoveSent(true);
        // 3) Another 500ms after the text changes, show the "Go to Dashboard" button
        setTimeout(() => {
          setShowDashboardButton(true);
        }, BUTTON_DELAY);
      }, TEXT_DELAY);
    }, ICON_ANIMATION_DURATION);
  };

  const handleGoToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Card sx={{width: '100%', p: 2}}>
        <CardContent>
          {/* PHASE: LOADING -> spinner */}
          {phase === 'LOADING' && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              gap={2}
            >
              <Typography variant="h4" gutterBottom>
                Checking your elderly’s phone...
              </Typography>
              <Typography variant="body1" color="text.secondary">
                We’re syncing settings and data. Please ensure your elderly’s phone is awake
                and connected.
              </Typography>
              <CircularProgress sx={{mt: 2}}/>
            </Box>
          )}

          {/* PHASE: READY -> show heart, "Send your love" button, optional "Go to Dashboard" */}
          {phase === 'READY' && (
            <Fade in={phase === 'READY'}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                gap={2}
              >
                {/* Icon container with scaleUpDown animation on click */}
                <HeartContainer playAnimation={loveAnimationPlaying}>
                  {loveSent ? (
                    <CheckCircleOutline sx={{fontSize: '5rem', color: 'green'}}/>
                  ) : (
                    <PulsatingHeart/>
                  )}
                </HeartContainer>

                <Typography variant="h4" gutterBottom>
                  {loveSent
                    ? 'Love sent. How cute!'
                    : 'Send your love'}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {loveSent
                    ? 'Now, go to the dashboard to get started.'
                    : 'Tap the button below to send a heart animation to your elderly’s device!'}
                </Typography>

                <Stack direction="row" spacing={2}>
                  {/* "Send your love" button always visible */}
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleSendLove}
                  >
                    Send your love
                  </Button>

                  {/* "Go to Dashboard" only visible after final animation steps */}
                  {showDashboardButton && (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleGoToDashboard}
                    >
                      Go to Dashboard
                    </Button>
                  )}
                </Stack>
              </Box>
            </Fade>
          )}
        </CardContent>
      </Card>
    </Container>
  );
}

export default OnboardingCheckElderly;
