import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import './Favorites.css';

const Favorites = () => {
  const [adminData, setAdminData] = useState(null);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTileIndex, setSelectedTileIndex] = useState(null);
  const [grid, setGrid] = useState(Array(8).fill(null));
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
          console.error('No access token found');
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin`, {
          headers: {Authorization: `Bearer ${token}`},
        });

        console.log("elderly", response.data.adminDetails.elderlies);

        setAdminData(response.data.adminDetails);
        setFilteredContacts(
          response.data.adminDetails.elderlies
            ?.flatMap((elderly) => elderly.contacts)
            ?.map((contact) => ({
              id: contact.id,
              name: contact.name,
              phoneNumbers: contact.phoneNumbers,
            })) || []
        );
        console.log("filtered contacts", filteredContacts);
      } catch (error) {
        console.error('Error fetching admin data:', error);
      }
    };

    fetchAdminData();
  }, [filteredContacts]);

  useEffect(() => {
    if (adminData) {
      const filtered = adminData.elderlies
        ?.flatMap((elderly) => elderly.contacts)
        ?.filter((contact) =>
          contact.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        ?.map((contact) => ({
          id: contact.id,
          name: contact.name,
          phoneNumbers: contact.phoneNumbers,
        }));
      setFilteredContacts(filtered || []);
    }
  }, [searchTerm, adminData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSelectedTileIndex(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTileClick = (index) => {
    setSelectedTileIndex(index);
  };

  const handleContactSelect = (contact) => {
    if (selectedTileIndex !== null) {
      const newGrid = [...grid];
      newGrid[selectedTileIndex] = contact;
      setGrid(newGrid);
      setSelectedTileIndex(null);
    }
  };

  const handleSave = async () => {
    const favorites = grid.filter(Boolean).map((contact) => contact.localId);
    console.log("favorites", favorites);

    const payload = {
      elderlyId: "prod-works-2", // TODO: change this
      commands: [
        {
          type: 'SET_FAVORITES',
          payload: {
            favoritesIds: favorites,
          },
        },
      ],
    };

    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        console.error('No access token found');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/command`, payload, {
        headers: {Authorization: `Bearer ${token}`},
      });

      console.log('Favorites saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving favorites:', error);
    }
  };

  return (
    <div className="favorites-container">
      <div className="phone-grid">
        {grid.map((contact, index) => (
          <div
            key={index}
            className="grid-tile"
            onClick={() => handleTileClick(index)}
          >
            {contact ? (
              <span>
                {contact.name} ({contact.phoneNumbers[0]?.phoneNumber || 'N/A'})
              </span>
            ) : (
              <span className="plus-icon">+</span>
            )}
          </div>
        ))}
      </div>

      {selectedTileIndex === null && (
        <button className="save-button" onClick={handleSave}>
          Save
        </button>
      )}

      {selectedTileIndex !== null && (
        <div className="dropdown" ref={dropdownRef}>
          <input
            type="text"
            placeholder="Search contact"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="contact-list">
            {filteredContacts.map((contact, index) => (
              <div
                key={index}
                className="contact-item"
                onClick={() => handleContactSelect(contact)}
              >
                <span className="contact-icon">📞</span> {contact.name} (
                {contact.phoneNumbers[0]?.phoneNumber || 'N/A'})
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Favorites;
