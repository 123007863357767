// src/components/AdminPage.js
import React from 'react';
import Dashboard from "./Dashboard/Dashboard";

function AdminPage() {

  return (
    <div className="admin-page">
      <Dashboard />
    </div>
  );
}

export default AdminPage;
