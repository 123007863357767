import React from 'react';
import './SettingsList.css';
import {useNavigate} from "react-router-dom";

const SettingsList = () => {
  const navigate = useNavigate();

  const handleEditSettingsClick = () => {
    navigate('/favorites');
  };

  const handleAddPhoneNumbersClick = () => {
    navigate('/contacts');
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  return (
    <div className="settings-container">
      <div className="settings-item" onClick={handleAddPhoneNumbersClick}>
        <span>Add Phone Numbers</span>
        <span className="icon">📞</span>
      </div>
      <div className="settings-item" onClick={handleSettingsClick}>
        <span>Edit Settings</span>
        <span className="icon">⚙️</span>
      </div>
      <div className="settings-item" onClick={handleEditSettingsClick}>
        <span>Edit Tiles</span>
        <span className="icon">🖼️</span>
      </div>
    </div>
  );
};

export default SettingsList;
