import React, {useState} from 'react';
import axios from 'axios';
import {Box, Button, Container, TextField, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

function Onboarding() {
  const navigate = useNavigate();
  const [elderlyDisplayName, setElderlyDisplayName] = useState('');

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('accessToken');

      const pairingToken = localStorage.getItem('pairingToken');
      if (!pairingToken) {
        throw new Error('No pairing token found in localStorage.');
      }

      const base64Url = pairingToken.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(window.atob(base64));
      const elderlyId = payload.userId;

      localStorage.setItem('elderlyId', elderlyId);

      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/elderly`,
        {
          elderlyId,
          name: elderlyDisplayName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate('/onboarding-check-elderly');
    } catch (err) {
      console.error('Error updating elderly user name:', err);
    }
  };

  return (
    <Container maxWidth="sm" sx={{mt: 5}}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        gap={2}
      >
        <Typography variant="h4" gutterBottom>
          Onboarding
        </Typography>
        <Typography variant="body1" color="text.secondary">
          You are about to manage your elderly’s phone. Please give a descriptive name
          so you can easily identify or refer to it in the future.
        </Typography>

        <TextField
          variant="outlined"
          label="Elderly Phone Display Name"
          value={elderlyDisplayName}
          onChange={(e) => setElderlyDisplayName(e.target.value)}
          fullWidth
        />

        <Button variant="contained" onClick={handleSubmit} sx={{mt: 2}}>
          Save & Continue
        </Button>
      </Box>
    </Container>
  );
}

export default Onboarding;
