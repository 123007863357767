import React, {useState} from 'react';
import {FaTrash} from 'react-icons/fa';
import axios from 'axios';
import './ContactForm.css';

const androidLabels = ["Mobile", "Home", "Work", "Other"];

const ContactForm = () => {
  const [contact, setContact] = useState({
    localId: "",
    name: "",
    phoneNumbers: [{label: "Mobile", phoneNumber: "", isPrimary: true}]
  });

  const handleNameChange = (e) => {
    setContact({...contact, name: e.target.value});
  };

  const handlePhoneNumberChange = (index, field, value) => {
    const updatedPhoneNumbers = [...contact.phoneNumbers];
    updatedPhoneNumbers[index][field] = value;
    setContact({...contact, phoneNumbers: updatedPhoneNumbers});
  };

  const addPhoneNumber = () => {
    const newPhoneNumber = {label: "Mobile", phoneNumber: "", isPrimary: false};
    setContact({...contact, phoneNumbers: [...contact.phoneNumbers, newPhoneNumber]});
  };

  const removePhoneNumber = (index) => {
    const updatedPhoneNumbers = contact.phoneNumbers.filter((_, i) => i !== index);
    setContact({...contact, phoneNumbers: updatedPhoneNumbers});
  };

  const setPrimaryPhoneNumber = (index) => {
    const updatedPhoneNumbers = contact.phoneNumbers.map((phone, i) => ({
      ...phone,
      isPrimary: i === index
    }));
    setContact({...contact, phoneNumbers: updatedPhoneNumbers});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      elderlyId: "prod-works-2", // TODO: Replace with dynamic elderlyId if needed
      commands: [
        {
          type: "ADD_CONTACTS",
          payload: {
            contacts: [
              {
                name: contact.name,
                phoneNumbers: contact.phoneNumbers
              }
            ]
          }
        }
      ]
    };

    try {
      const token = localStorage.getItem("accessToken"); // Assuming token is stored in localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/command`,
        payload,
        {headers: {Authorization: `Bearer ${token}`}}
      );
      console.log("Contact saved successfully:", response.data);
    } catch (error) {
      console.error("Error saving contact:", error);
    }
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <h2>Add Contact</h2>

      <label>
        Name:
        <input
          type="text"
          value={contact.name}
          onChange={handleNameChange}
          required
        />
      </label>

      <h3>Phone Numbers</h3>
      {contact.phoneNumbers.map((phone, index) => (
        <div key={index} className="phone-number-group">
          <label>
            Label:
            <select
              value={phone.label}
              onChange={(e) =>
                handlePhoneNumberChange(index, "label", e.target.value)
              }
            >
              {androidLabels.map((label) => (
                <option key={label} value={label}>
                  {label}
                </option>
              ))}
            </select>
          </label>

          <label>
            Phone Number:
            <input
              type="text"
              value={phone.phoneNumber}
              onChange={(e) =>
                handlePhoneNumberChange(index, "phoneNumber", e.target.value)
              }
              required
            />
          </label>

          <label className="primary-checkbox">
            <input
              type="radio"
              name="primaryPhoneNumber"
              checked={phone.isPrimary}
              onChange={() => setPrimaryPhoneNumber(index)}
            />
            Primary
          </label>

          {index > 0 && (
            <button
              type="button"
              className="delete-button"
              onClick={() => removePhoneNumber(index)}
            >
              <FaTrash/>
            </button>
          )}
        </div>
      ))}

      <button type="button" className="add-phone-button" onClick={addPhoneNumber}>
        +
      </button>

      <button type="submit" className="save-contact-button">
        Save Contact
      </button>
    </form>
  );
};

export default ContactForm;
