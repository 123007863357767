import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth} from './firebase';
import SignIn from './components/SignIn';
import AdminPage from './components/AdminPage';
import SettingsList from "./components/SettingsList/SettingsList";
import Favorites from "./components/Favorites/Favorites";
import ContactForm from "./components/ContactForm/ContactForm";
import Settings from "./components/Settings/Settings";
import ElderlyDashboard from "./components/ElderlyDashboard/ElderlyDashboard";
import Onboarding from "./components/Onboarding/Onboarding";
import OnboardingCheckElderly from "./components/Onboarding/OnboardingCheckElderly";

function App() {
  const [user] = useAuthState(auth);

  return (
    <Router>
      <Routes>
        <Route path="/signin/:ptoken?" element={<SignIn/>}/>
        <Route path="/onboarding" element={<Onboarding/>}/>
        <Route path="/onboarding-check-elderly" element={<OnboardingCheckElderly/>}/>
        <Route
          path="/admin"
          element={
            user
              ? <AdminPage/>
              : <Navigate to="/signin" replace/>
          }
        />
        <Route
          path="/settinglist"
          element={user ? <SettingsList/> : <Navigate to="/signin" replace/>}
        />
        <Route
          path="/favorites"
          element={user ? <Favorites/> : <Navigate to="/signin" replace/>}
        />
        <Route
          path="/contacts"
          element={user ? <ContactForm/> : <Navigate to="/signin" replace/>}
        />
        <Route
          path="/settings"
          element={user ? <Settings/> : <Navigate to="/signin" replace/>}
        />
        <Route path="/elderly/:elderlyName" element={<ElderlyDashboard/>}/>
        {/* Redirect unknown routes to Sign In */}
        <Route path="*" element={<Navigate to="/signin" replace/>}/>
      </Routes>
    </Router>
  );
}

export default App;
