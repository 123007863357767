import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState(null);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        if (!token) {
          navigate("/signin");
          return;
        }
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin`, {
          headers: {Authorization: `Bearer ${token}`},
        });
        
        setAdminData(response.data.adminDetails);
      } catch (error) {
        console.error("Error fetching admin data:", error);
        navigate("/signin");
      }
    };

    fetchAdminData();
  }, [navigate]);

  const handleElderlyClick = (elderlyName) => {
    navigate(`/elderly/${elderlyName}`);
  };

  return (
    <div className="dashboard">
      <div className="settings-icon">⚙️</div>
      <h1 className="dashboard-title">Dashboard</h1>
      {adminData && <p className="admin-name">Welcome, {adminData.name}</p>}
      <h2 className="notifications-title">Recent notifications</h2>
      <div className="notifications">
        <div className="notification">
          <span className="icon error">❗</span>
          <span className="text">Blum Nagyi - Battery</span>
        </div>
        <div className="notification">
          <span className="icon success">✔️</span>
          <span className="text">Anyu Nagyi - Battery</span>
        </div>
      </div>
      <h2 className="elderlies-title">Select Elderly</h2>
      <div className="elderlies">
        {adminData?.elderlies?.map((elderly) => (
          <div
            key={elderly.name}
            className="elderly"
            onClick={() => handleElderlyClick(elderly.name)}
          >
            {elderly.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
