import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import "./ElderlyDashboard.css";

const ElderlyDashboard = () => {
  const {elderlyName} = useParams();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="elderly-dashboard">
      <button className="back-button" onClick={() => navigate("/")}>
        ← Back
      </button>
      <h1 className="title">{elderlyName}</h1>
      <h2 className="subtitle">Dashboard</h2>
      <div className="menu">
        <div className="menu-item">
          <span className="icon error">❗</span>
          <span className="text">Notifications</span>
        </div>
        <div className="menu-item"
             onClick={() => handleNavigation("/contacts")}
        >
          <span className="icon circle"></span>
          <span className="text">Add contact</span>
        </div>
        <div
          className="menu-item"
          onClick={() => handleNavigation("/favorites")}>
          <span className="icon circle"></span>
          <span className="text">Contact order</span>
        </div>
        <div className="menu-item">
          <span className="icon circle"></span>
          <span className="text">App order</span>
        </div>
        <div className="menu-item">
          <span className="icon circle"></span>
          <span className="text">Where are you?</span>
        </div>
        <div className="menu-item"
             onClick={() => handleNavigation("/settings")}
        >
          <span className="icon circle"></span>
          <span className="text">Settings</span>
        </div>
        <div className="placeholder"></div>
        <div className="placeholder"></div>
      </div>
    </div>
  );
};

export default ElderlyDashboard;
